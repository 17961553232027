body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Makes sure that the shadow goes above the content */
footer,
header {
  z-index: 1000;
}

footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

.svc-creator__content-wrapper {
  min-height: 75vh;
}

.svc-tab-designer,
.svc-plugin-tab__content {
  /* overwrite inline style */
  overflow: visible !important;
  padding-bottom: 30px; /* to account for the 'please pay' bar */
}

.svc-flex-column {
  /* overwrite inline style */
  overflow: visible !important;
  padding-bottom: 15px; /* to account for the 'please pay' bar */
}

.st-root-modern {
  overflow: hidden;
  padding-bottom: 30px; /* to account for the 'please pay' bar */
}
